import {
  BBDBAP,
  BBDEAP,
  ENVIRONMENT,
  ROOT_URL,
  HOSTNAME,
} from 'utils/environment';

export const tokeName = 'token';
export const lastTimestampName = 'last_action_at';

export function isAuthenticated() {
  return (localStorage.getItem(tokeName) && true) === true;
}

export function setToken(token) {
  return localStorage.setItem(tokeName, token);
}

export function getToken() {
  return localStorage.getItem(tokeName);
}

export function headers() {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
}

export function isUnauthorized(error) {
  if (error && error.response && error.response.status === 401) {
    return true;
  }

  return false;
}

export function signOut(plan_type, locale) {
  // clean local storage
  localStorage.removeItem(tokeName);
  localStorage.removeItem(lastTimestampName);

  // redirect to marketing pages
  if (ENVIRONMENT === 'DEVELOPMENT') {
    window.location = `https://${ROOT_URL}/signin`;
  }

  if (ENVIRONMENT === 'STAGING') {
    if (BBDEAP) {
      if (locale === 'en') {
        window.location = `https://bbdeap-stg.pcpeopleconnect.com`;
      } else {
        window.location = `https://bbdeap-stg.peoplevousconnecte.com`;
      }
    }
    if (BBDBAP) {
      if (locale === 'fr') {
        window.location = 'https://bbdcarepath-bap-stg.pcpeopleconnect.com';
      } else {
        window.location = 'https://bbdcarepath-bap-stg.peoplevousconnecte.com';
      }
    }
  }

  if (ENVIRONMENT === 'PRODUCTION') {
    if (BBDEAP) {
      if (locale === 'en') {
        window.location = `https://bbdeap.pcpeopleconnect.com`;
      } else {
        window.location = `https://bbdpae.peoplevousconnecte.com`;
      }
    }
    if (BBDBAP) {
      if (locale === 'fr') {
        window.location = 'https://bbdcarepath-bap.pcpeopleconnect.com';
      } else {
        window.location = 'https://bbdcarepath-pap.peoplevousconnecte.com';
      }
    }
  }

  if (plan_type === 'eap') {
    window.location = `${ROOT_URL}/eap`;
  } else {
    window.location = `https://${HOSTNAME}`;
  }
}

export function allowedToViewMetrics() {
  /*
    2041 - ben+admin@inkblottherapy.com
    4245 - marion@inkblottherapy.com
    27 - arash@inkblottherapy.com
    3239 - judy.plotkin@peoplecorporation.com
    5179 - dana.hurst@peoplecorporation.com
    8850 - brendan.hickey@peoplecorporation.com
    13865 - mira+marchel@inkblottherapy.com
    5190 - melissa.fallis@peoplecorporation.com
    9043 - isabelle.lipari@lacorporationpeople.com
    32300 - yiwen+test+pc@inkblottherapy.com
    124822 - miguel.friede@lacorporationpeople.com
    90493 - ambu.packiarajan@peoplecorporation.com
    56721 - siva+pc2+test@inkblottherapy.com
    381770 - nadia.lubsey@peoplecorporation.com
    322304 - elora+admin@inkblottherapy.com
    6228 - nicholas+admin@inkblottherapy.com
    322596 - melissa+admin@inkblottherapy.com
    429361 - melissa+pcdemo@inkblottherapy.com
    568453 - sujith+pc+test@inkblottherapy.com
    316003, - suji+pc+test@inkblottherapy.com
    809974, - marija@bestliferewarded.com
    841857 - Hiwot.Regasa@peoplecorporation.com
    840815 - Allison.Rew@peoplecorporation.com
    847417 - eliana.burgos+pc@inkblottherapy.com
    3654 - adam+admin@inkblottherapy.com
    975289 - darya.molaei@peoplecorporation.com
    845614 - maria.butkovic@greenshield.ca
    1046812 - mercedes+pctest@inkblottherapy.com
    1010714 - marija.prazic@peoplecorporation.com
    1085584 - Dave.Seagrave@peoplecorporation.com
    1163784 - melissa.hughes@peoplecorporation.com
    1012187 - miguel.friede@peoplecorporation.com
    */
  const allowedIds = [
    3239,
    5179,
    8850,
    13865,
    5190,
    9043,
    32300,
    124822,
    90493,
    317243,
    56721,
    381770,
    322304,
    6228,
    322596,
    429361,
    568453,
    316003,
    809974,
    841857,
    840815,
    847417,
    3654,
    975289,
    845614,
    1046812,
    1010714,
    1085584,
    1163784,
    1012187,
  ];
  let id;

  if (getToken()) {
    id = Number.parseInt(getToken().split(':')[0], 10);
  }

  return allowedIds.includes(id);
}
